#root {
  background-color: #FEFAEE !important;
}

.cardlist {
  background-color: #FEFAEE !important;
  border-radius: 0.6rem;
  color: black;
  width: 12%;
  height: 11rem;
  padding-top: 1rem;
  margin-bottom: -4rem;
  box-shadow: 1;
  -webkit-box-shadow: 6px 1px 20px -1px rgba(0,0,0,0.49);
  -moz-box-shadow: 6px 1px 20px -1px rgba(0,0,0,0.49);
  box-shadow: 6px 1px 20px -1px rgba(0,0,0,0.49);
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.barrinhamark {
  margin-left: 2rem;
  width: 8%;
  height: 0.35rem;
  background-color: #074900;
  color: whitesmoke;
  
}

.linkmarket {
  
  font-weight: bold;
  color: black;
  text-decoration: none;
}


.card-listed {
  background-color: #FEFAEE !important;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 8rem;
}

.marketcard {
  border-radius: 0.6rem;
  width: 90%;
  height: 50%;
  margin-bottom: 0.5rem;
}

.titlemark {
  background-color: #FEFAEE;
  display: flex;
  justify-content: flex-start;
  text-align: center;
  margin: 0;
  padding-bottom: 4rem;
  padding-left: 2rem;
  padding-top: 2rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
}

.subtitlemark {
  padding-left: 2rem;
  font-size: 1.5rem;
  padding-bottom: 3rem;
}







@media (max-width: 1000px) {
 .cardlist{
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  height: auto;
  margin-bottom: 0;
  padding-bottom: 0;
  
 }
 .marketcard {
  border-radius: 0.6rem;
  width: 90%;
  height: 50%;
  margin-bottom: 0.5rem;
  margin-top: -0.7rem;
}

.card-listed {
  padding-bottom: 3rem;
}
 

 .titlemarket {
  font-size: 0.5rem;
 }

 .contentmarked {
  font-size: 0.5rem;
  text-align: center;
 }

 
}

@media (max-width: 768px) {
  .barrinhamark {
    
    margin-left: 0.5rem;
  }

  .titlemark {
    font-size: 2rem !important;
    padding-bottom: 1rem !important;
    padding-left: 0.5rem;}

  .subtitlemark {
    padding-left: 0.5rem;
    font-size: 1rem;
    padding-bottom: 3rem;
  }

  
}

@media (max-width: 480px) {


  
   .contentmarked {
    font-size: 0.4rem;
    text-align: center;
   }
}
