
.metade {
  display: flex;
  flex-direction: row;
  background-color: rgb(31, 31, 31);
}





.loginSec {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  font-family: "Montserrat", sans-serif;
  padding-top: 8rem;
  padding-bottom: 18rem;
  text-align: left;
  margin-right: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.loginSecMain {
  width: 49%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(31, 31, 31);
}

.loginSec {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  font-family: "Montserrat", sans-serif;
  padding-top: 6rem;
  padding-bottom: 18rem;
  text-align: center;
  margin-right: 4rem;
}

.logintitle {
  padding-top: 4rem;
text-align: center;
font-weight: 600;
font-size: 3.6rem;
}

.loginsubtitle {
  color: rgb(31, 31, 31);
  background-color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 1rem;
  padding-top: 1rem;
  text-align: center;
}

.loginbutton {
  margin-top: 0.4rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  color: white;
  font-weight: 600;
  border: 0;
  background-color: #FFBB3C;
}
 
.caption-{
  color: grey;
}

.SubLink{
  color: rgb(22, 96, 255);
}

.linkemail {
  margin-top: 2rem;
  font-size: 0.9rem;
}

.LoginEmailLink {
  color: white;
  font-weight: 600;
  text-decoration: none;
}

@media screen and (max-width: 760px) {
  .loginSec {

    padding-left: 5rem;
    padding-right: 7rem;
  }

  .loginSecMain {
    width: 120%;
  }



}