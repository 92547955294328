
.barrinhainfo{
  width: 15%;
  height: 4px;
  background-color: rgb(7, 73, 0);
  color: whitesmoke;
  margin-bottom: 2rem;
}

.espaçamentoinfo {
  margin-top: 3rem;
}

.maintitleinfo{
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 2rem;
  color: rgb(66, 64, 55);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.mainsubinfo{
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: -0.09rem;
  color: rgb(90, 90, 90);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.rowinfo {
  display: flex;
  flex-direction: row;
}

.titleinfo {
  display: flex;
  text-align: left;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  padding-right: 0.5rem;
  
}

.titleinfo2 {
  display: flex;
  text-align: left;
  font-size: 1.4rem;
  margin-bottom: 2.5rem;
  padding-top: 0.7rem;
}

.lista2 {
  display: flex;
  color: rgb(31, 31, 31);
  padding-left: 1.5rem;
  text-align: left;
  flex-direction: column;
  font-weight: 600;
  list-style: disc; 
  padding-bottom: 2rem; 
}

.iteminfo {
  padding-top: 0.3rem;
}


.uptitle{
  display: flex;
  text-align: left;
  color:rgb(62, 139, 255);
  font-size: 1rem;
  font-weight: 400;
  
}

.title1info{
  display: flex;
  text-align: left;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}


.buttoninfo1 {
  margin-top: 0.5rem;
  border: 0;
  width: 95%;
  background-color: aliceblue;
  color: blue;
}

.textinfo2 {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  display: flex;
  text-align: left;
  font-size: 0.85rem;
  padding-right: 1.8rem;
  color: grey;
}


@media screen and (max-width: 760px) {


  .barrinhainfo {
    margin-bottom: 0.5rem !important;
  }

  

  .iteminfo {
    padding-top: 0.1rem;
    font-size: 0.5rem;
  }

  .text1up {
    display: flex;
    text-align: left;
    font-size: 0.45rem;
    padding-right: 0.3rem;
    padding-bottom: 0.3rem;
    color: grey;
  }
  

  .lista2 {
    display: flex;
    color: rgb(31, 31, 31);
    padding-left: 1.5rem;
    text-align: left;
    flex-direction: column;
    font-weight: 600;
    list-style: disc; 
    padding-bottom: 1rem; 
  }


  .barrinhainfo{
    width: 15%;
    height: 2px;
    background-color: rgb(7, 73, 0);
    color: whitesmoke;
    margin-bottom: 1rem;
  }

  .title1info{
    display: flex;
    text-align: left;
    font-size: 1.2rem;
    margin-bottom: 0.4rem;
  }

  .titleinfo2 {
    display: flex;
    text-align: left;
    font-size: 1.4rem;
    margin-bottom: 0.7rem;
    padding-top: 0.2rem;
  }



  .textinfo2 {
    padding-top: 0rem;
    padding-bottom: 0rem;
    display: flex;
    text-align: left;
    font-size: 0.4rem;
    padding-right: 1.8rem;
    color: grey;
  }


}