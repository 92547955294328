

.table {
  width: 98%;
  background-color: #FEFAEE;
  font-family: "Public Sans", sans-serif;
}

#tablecheck {
  box-shadow: 10px 10px 46px -17px rgba(0,0,0,0.49);
  -webkit-box-shadow: 10px 10px 46px -17px rgba(0,0,0,0.49);
  -moz-box-shadow: 10px 10px 46px -17px rgba(0,0,0,0.49);
}


.tablecheck {
  width: 90%;
}

.progressbar {
  width: 90%;
  margin-left: 2.5rem;
  height: 1.5rem;
  border-radius: 2rem;
  margin-bottom: 3rem;
  font-size: 1rem;
  font-weight: 500;
}

.tablecard {
  margin-top: 3rem;
  border: none;
  font-family: "Public Sans", sans-serif;
}

.tarefa {
  display: flex;
  justify-content: flex-start;
}

.rowtdC {
  justify-content: start;
  text-align: start !important;
  font-size: 1rem;
  font-family: "Public Sans", sans-serif;
}


.pendentediv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.warning {
  padding-top: 0.2rem;
  width: 1.8rem;
  height: 1.8rem;
}

.Concluido {
  width: 1.6rem;
  height: 1.4rem;
}

.textpendente {
  padding-top: 0.5rem;
  margin-left: 0.4rem;
  font-size: 1rem;
  font-family: "Public Sans", sans-serif;
}



.olho:hover {
cursor: pointer;
}

.table th, .table td {
  text-align: center;
  vertical-align: middle;
  background-color: #FEFAEE;
  
}

.logomediatable {
margin-right: 5px;
margin-bottom: 2px;
}

.logoaltatable {
  margin-bottom: 4px;
  margin-right: 3px;
}


.logocrittable {
  margin-bottom: 3px;
  margin-right: 2px;
}





