

#logincard{
  width: 1000px;
  height: 700px;
  background-color: #FEFAEE;
  display: flex;
  flex-direction: row;
}

.blogin {
  overflow: hidden; /* Desabilita o scroll */
  position: fixed; /* Impede o movimento */
  width: 100vw; /* Garante que a largura não seja alterada */
  height: 100vh; /* Garante que a altura não seja alterada */
}

.logincard {
  font-family: "Public Sans", sans-serif;
  margin-top: 3.4rem;
  padding-left: 2rem;
  color: #8C7D81;
}

.logincard2 {
  font-family: "Public Sans", sans-serif;
  margin-top: 0rem;
  padding-left: 2rem;
  color: #8C7D81;
  margin-bottom: 0.4rem;
}

.logintext {
  font-size: 2rem;
  padding-left: 2rem;
  padding-bottom: 0.5rem;
}

.bar {
  height: 3px;
  width: 103%;
  background-color: #D9D9D9;
}

#buttonLogin {
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 180px;
  
}

#btn-submit {
  display: flex;
  justify-content: center;
  background-color: #59424B;
  color: white;
  border-radius: 2rem;
  font-weight: 500;
  margin-top: 1rem;
}

#btn-submit2 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #59424B;
  color: white;
  width: 150px;
  height: 40px;
  border-radius: 2rem;
  font-weight: 500;
  margin-top: 0rem;
  border: none;
}

.CardInput3 {
  
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.CardInput {
  margin-bottom: 2rem;
  margin-top: 4rem;
  display: flex;
  justify-content: center;
}

.CardInput2 {

  display: flex;
  justify-content: center;
}


.form-label {
  font-family: "Public Sans", sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  margin-top: 7px;
  margin-bottom: 5px !important;
}

#formemail {
  border: none;
  background-color: #F3EED5;
  width: 310px;
  padding-left: 3.5rem;
}

#formempresa {
  border: none;
  background-color: #F3EED5;
  width: 310px;
  padding-left: 3.5rem;
}

#formempresa {
  border: none;
  background-color: #F3EED5;
  width: 310px;
  padding-left: 3.5rem;
}


#formurl {
  border: none;
  background-color: #F3EED5;
  width: 310px;
  padding-left: 3.5rem;
}


#formname {
  border: none;
  background-color: #F3EED5;
  width: 310px;
  padding-left: 3.5rem;
}

#formtelefone {
  border: none;
  background-color: #F3EED5;
  width: 310px;
  padding-left: 3.5rem;
}



#formsenha {
  border: none;
  background-color: #F3EED5;
  width: 310px;
  padding-left: 3.5rem;
}

.comboform {
  display: flex;
  flex-direction: row;
  height: 80% !important;
}

.PL {
  position: fixed;
}

.formcss {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.textoinferior {
  text-decoration: none;
  color: black !important;
  text-align: center;
  margin-top: 4rem;
  font-weight: 200;
  font-style: italic;
}

.textoinferior2 {
  text-decoration: none;
  color: black !important;
  text-align: center;
  margin-top: 1rem;
  font-weight: 200;
  font-style: italic;
}

.h5troca {
  text-decoration: none;
  color: rgb(34, 34, 34) !important;
}

.olhologin {
  position: relative;
  display: flex;
  align-self: center;
  justify-self: flex-end;
  
  margin-left: -1.9rem;
  
}


.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loading-spinner {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@media screen and (max-height: 760px) {
  #logincard{
    width: 1000px;
    height: 600px;
    background-color: #FEFAEE;
    display: flex;
    flex-direction: row;
  }


#ajuste {
  margin-bottom: 0.3rem !important;
}
}

@media screen and (max-height: 590px) {
  #logincard{
    width: 1000px;
    height: 440px;
    background-color: #FEFAEE;
    display: flex;
    flex-direction: row;
  }

  .CardInput2 {
    justify-content: space-around;
  }

  .comboform {
    height: 20%;
  }

  .gridform {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 0.5rem;
  }

  #formemail {
    height: 50px;
  }
  
  #formempresa {
    height: 50px;
  }
  
  #formempresa {
    height: 50px;
  }
  
  
  #formurl {
    height: 50px;
  }
  
  
  #formname {
    height: 50px;
  }
  
  #formtelefone {
    height: 50px;
  }
  
  
  
  #formsenha {
    height: 50px;
  }




#ajuste {
  margin-bottom: 0.0rem !important;
}
}


@media screen and (max-width: 450px) {

  #logincard{

    width: 95%;
  }

  .h5troca {
    text-decoration: none;
    color: rgb(34, 34, 34) !important;
    font-size: 1rem;
  }

  #btn-submit2{
    margin-top: 2rem !important;
    font-size: 1rem !important;
  }

  #btn-submit{
    margin-top: 1rem !important;
    font-size: 1rem !important;
  }

  #formemail {
    height: 50px;
    width: 90%;
  }
  
  #formempresa {
    height: 50px;
    width: 90%;
  }
  
  #formempresa {
    height: 50px;
    width: 90%;
  }
  
  
  #formurl {
    height: 50px;
    width: 90%;
  }
  
  
  #formname {
    height: 50px;
    width: 90%;
  }
  
  #formtelefone {
    height: 50px;
    width: 90%;
  }
  
  
  
  #formsenha {
    height: 50px;
    width: 90%;
  }

}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
