

.input{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 0.5rem;

  margin-right: 2rem;
  margin-left: 2rem;
}

.confirmbutton {
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
}

.altbody {
  display: flex;
  flex-direction: column;
}