.home {
  max-width: 100%;
  overflow-x: hidden;
}

.login {
  list-style: none;
  text-decoration: none;
  background-color: #FEFAEE;
}



.bgc {
  background-color: #FEFAEE;
  list-style: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul {
  list-style-type: none;
}


.SignIn {
  display: flex;
  color: black;
  justify-content: center;
  margin-top: -1.5rem;
  margin-right: 1rem;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  list-style: none;
  list-style-type: none;
  background-color: aliceblue;

}



.groupsolution {
  background-color: #FFBB3C;

}

.m {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
}


@media screen and (max-width: 560px) {

  .login {
    
    text-decoration: none !important;
    list-style: none !important;
  }


}