.backgrounds {
  display: flex;
  height: 22rem;
  width: 100%;
  background-image: url('../components/img/photos/back5.png');
  background-size: cover;
}

.backgrounds2 {
  display: flex;
  height: 22rem;
  width: 100%;
  background-image: url('../components/img/photos/tigre.png');
  background-size: cover;
}

.cardSE {
  padding-top: 12rem;
  padding-left: 5rem;

}

.cardSC {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 12rem;
  padding-left: 5rem;
  width: 90%;

}

.title {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.titlecarrd1{
  font-size: 2;
}
.titlecard {
  padding-top: 0.5rem;
  padding-left: 1.4rem;
  padding-right: 1.5rem;


}

.t4 {
  width: 34rem;
  padding-right: 2rem;
  height: 8rem;
  background-color: #FEFAEE;

}

.t6 {
  width: 34rem;
  padding-right: 2rem;

  height: 6rem;
  background-color: #FEFAEE;

}

.contatogridcheck {
  display: flex;
  flex-direction: row;
  
}

.porwhatsapp {
  margin-left: 2rem;
}

.t5 {
  width: 40%;
  padding-right: 4rem;
  margin-top: -6rem;
  height: 35rem;
  margin-left: 4rem;
  padding-left: 2rem;
  padding-top: 1.5rem;
  background-color: #FEFAEE;
}

.TInput {
  padding-bottom: 1rem;
}

.espacamento-ponto {
  background-color: #232931;
  padding-bottom: 22rem;
  margin: 0;
}

@media screen and (max-width: 768px) {

  .t6 {
    width: 18rem;
    padding-right: 2rem;
    font-size: 2.5rem;
    height: 4.5rem;
    background-color: #FEFAEE;
  
  }


  .cardSC {
    display: flex;
    flex-direction: column;
    padding-top: 9rem;
    margin-right: -5rem;
  }

  .t5 {
    width: 15rem;
    padding-right: 1rem;
    height: 900%;
    margin-left: -2.8rem;
    padding-left: 1.5rem;
    margin-top: 2rem;
    font-size: 13px;
    padding-bottom: 0.7rem;
  }

  .espacamento-ponto {
    background-color: #232931;
    padding-bottom: 34rem;
    margin: 0;
  }


  .t4 {
    width: 15rem;
    font-size: 15px;
    margin-left: -2.8rem;
    background-color: #FEFAEE;
  }

  .title {
    font-size: 18px;
    padding-right: -2rem;
    margin-right: -2rem;
    margin-left: -0.3rem;
    font-weight: bold;
    padding-bottom: -1rem;
  }

  #subtitle {
    font-size: 12px;
    margin-right: -1rem;
    margin-left: -0.5rem;
  }


}


@media screen and (max-width: 425px) {

  .t6 {
    width: 14rem;
  }

  .cardSE {
    padding-left: 1rem;
  }
  
  .t5 {
    width: 17rem;

  }

  .cardSC {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 9rem;
    
  }

  .backgrounds2 {
    height: 100px;
  }


}
