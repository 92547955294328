


.container2{

  display: flex;
  flex-direction: column;
}


.table{}