.fix {
  position: fixed;
  width: 15rem;
 
}

.checklisttext {
  margin-top: 3rem;
  margin-bottom: 3rem;
  color: #59424B;
  font-weight: 400;
  font-family: "Public Sans", sans-serif;
}

.grafico-gauge {
  height: 285px;
  width: 650px;
  margin-top: -4rem;
  align-self: center;
}



.imgside {
  margin-right: 0.6rem;
}

.imgtop {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.5rem;
}

#Brand {
 margin-left: -1.5rem;
 margin-bottom: -2rem;
 list-style: none !important;
}

.sidebar-item {
  text-decoration: none !important;
  list-style: none !important;
}




#a4 { 
  margin-top: 0.6rem;
  margin-left: -0.4rem;
  width: 2rem;
  font-size: 0.55rem;
  text-align: flex-start !important;
  text-decoration: none !important;
  list-style: none !important;
}

.sidebar-nav {
  background: #59424B !important;
  
}

.sidebar-brand {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center;

  text-decoration: none;
  size: 1.5rem;
  color: whitesmoke  !important  ;
  background: #59424B !important;
  font-family: "Public Sans", sans-serif;
  
}

.sidebar-header {
  color: whitesmoke  !important  ;
  background: #59424B !important;
  font-family: "Public Sans", sans-serif;
  font-size: 0.8rem !important;
  
}

.sidebar-item :hover {
  color: whitesmoke  !important  ;
  background: #40252C !important;
  font-family: "Public Sans", sans-serif;
  margin-top: 1.4rem;
  font-size: 1.1rem;
  
}

.sidebar-link {
  color: whitesmoke  !important  ;
  background: #59424B !important;
  font-family: "Public Sans", sans-serif;
  margin-top: 1.4rem;
  font-size: 1.1rem;
  
}

.background {
  width: 240px;
  height: 80em;
}

.widhtfix {
  width: 240px;
}

.didigod { 
  width: 100%;
}

.goddidi {
  width: 240px;
}

.topbar {
  background-color: #FEFAEE ;
  padding-top: 1rem;
  padding-bottom: 0.7rem;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0px 10px 25px 0px rgba(0,0,0,0.1);
  font-family: "Public Sans", sans-serif;
}

.PR {
  font-size: 1.3rem;
  margin-top: 0.2rem;
  padding-left: 0.5rem;
}

.acordionbody {
  display: flex;
  flex-direction: column;
}

.accordion {
  margin-top: 4rem;
  width: 32%;
  background-color: #FEFAEE !important;
  font-family: "Public Sans", sans-serif;
}

.accordionitem {
  background-color: #FEFAEE !important;
  font-family: "Public Sans", sans-serif;
  
}

.accordion-button {
  background-color: #FEFAEE !important ;
  font-family: "Public Sans", sans-serif;

}

.buttonacord{
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  width: 30%;
  background-color: #59424B !important;
  border: none !important;
  border-color: #40252C;
  font-size: 1.1rem !important;
  font-weight: 500 !important;
  padding-top: -2rem !important;
  text-align: center !important;
}

.Bodytop{
  margin-bottom: 1rem;
  font-size: 1rem;
}

.form {
  padding-bottom: 0.5rem;
}


.accordionheader {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 0;
  background-color: #FEFAEE !important;

}

.imgsub{
  padding-bottom: 0.2rem;
}



.side {
  display: flex;
  flex-direction: row;
  background-color: #FEFAEE;
}

.content {
  width: 100%;
  background-color: #FEFAEE;
  font-family: "Public Sans", sans-serif;
}


.CardSection {
  
  width: 83%;
}

.card1g {
  display: flex; 
  flex-direction: column;
}



.CardS {
  margin-top: 3rem;
  font-family: "Public Sans", sans-serif;
}

@media screen and (max-width: 1850px) { 


.grafico-gauge {
  height: 132px;
  width: 275px;
  margin-top: -3em;
  margin-bottom: 2rem;
  align-self: center;
}

.card1g {
  display: flex;
  justify-content: center;
  flex-direction: column;

}

.checklisttext {
  width: 90%;
  margin-top: 3rem;
  margin-left: 2rem;
  margin-bottom: 3rem;
  color: #59424B;
  font-weight: 400;
  font-family: "Public Sans", sans-serif;
}


}






@media screen and (max-width: 768px) {

  #a4 { 
    display: none;
  }  

  .CardS {
    margin-top: 3rem;
  }

 

  .imgt3 {
    width: 3.3rem;
    height: 3.3rem;
    margin-left: 1.3rem;
  }

  .background {
    height: 42em;
  }

  .content {
    margin-bottom: -25rem;
    padding-bottom: 1;
  }

  .fix {
    position: fixed;

  }

  #Side-item {
    font-size: 0px;
  }

  #sidebar {
    width: 3.4rem;
  }

  .align-middle {
    font-size: 0px;
  }

  .sidebar-link {
    margin-left: -0.9rem;
    padding-left: 10px;
  }

  .icon {
    size: 10px !important;
    margin-right: 4px !important;
  }

  .sidebar-header {
    font-size: 0 !important;
  }

  .sidebar-brand {
    padding-top: 1.5rem !important;
    margin-left: -1.29rem;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  #Brand {
    font-size: 14px !important;

  }

  .card-body {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .card-title,
  h5 {
    font-size: 0.63rem !important;
  }

  .btn,
  btn-primary {
    margin-top: -1.5rem !important;
    font-size: 0.5rem !important;
    margin-bottom: -1rem;

  }

  .card-text {
    font-size: 0.45rem;

  }

  .grafico-gauge {
    display: flex;
    margin-top: -1.2rem !important;
    margin-bottom: 0.5rem;
    width: 100%;
    height: 150%;
    text-align: center;
  }
}