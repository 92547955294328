.h2off {
  
  padding-bottom: 0rem;
  font-weight: bold;
}

.grouptext {
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.textoffgroup{
  display: flex;
  text-align: center;
  flex-direction: column;
  padding-right: 2rem;
  padding-left: 3rem;
  
}

@media screen and (max-width: 400px) {


  .textoffgroup{
    padding-right: 1rem;
    padding-left: 1rem;
  }

}