.Card1Up {
  display: flex;
  text-align: center;
  background-color: whitesmoke;
  color: rgb(51, 50, 50);
  flex-direction: column;
  justify-content: space-around !important;

}

.cardresponsive {
  margin-top: -3rem;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
}

.cardbody {
  display: flex;
  flex-direction: column;
  justify-content: space-around !important; 
}

.Card1Co {
  display: flex;
  text-align: center;
  justify-content: center;
  background-color: whitesmoke;
  color: rgb(51, 50, 50);
  padding-right: 1rem;
  padding-left: 1rem;
}

.iconup2 {
  padding-right: 3px;
  

}

.L3 {
  color: black !important;
  text-decoration: none !important;
}

.Card2Up {
  display: flex;
  text-align: center;
  background-color: whitesmoke;
  color: rgb(51, 50, 50);
}

.Card3Up {
  display: flex;
  text-align: center;
  background-color: whitesmoke;
  color: rgb(51, 50, 50);
}

.title1up {
  display: flex;
  text-align: left;
  font-size: 1.8rem;
  padding-bottom: 0.6rem;
}

.text1up {
  display: flex;
  text-align: left;
  font-size: 0.85rem;
  padding-right: 1.8rem;
  color: grey;
}

.text1Co {
  display: flex;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  padding-right: 1.8rem;
  color: grey;
  padding-top: 0.8rem ;

}





.buttonup1 {
  width: 95%;
  background-color: black;
  border: none;
  
}

.buttonup3 {
  width: 95%;
  background-color: black;
  border: none;
  
}

.buttonup1:hover {
  width: 95%;
  background-color: rgb(36, 36, 36) !important;
  border: none;
}
.buttonup2 {
  width: 95%;
  background-color: #FFBB3C;
  color: black;
  font-weight: 500;
  border: none;
  
  
}

.buttonup2:hover {
  width: 95%;
  background-color: #f8aa18;
  color: black;
  font-weight: 500;
  border: none;
}

.buttonco1 {
  width: 95%;
  font-size: 1rem;
  font-weight: 500;
  background-color: palevioletred;
  border: none;
}

.kitbutton {
 
    margin-top: auto;
  
}

.buttonco1:hover{
  background-color: rgb(255, 92, 119);
}

.lista1up {
  display: flex;
  color: grey;
  text-align: left;
  font-size: 0.90rem;
  margin-bottom: 1rem;
  font-weight: 600;
  
}

.lista3up {
  display: flex;
  color: grey;
  text-align: left;
  font-size: 0.90rem;
  margin-top: 10px;
  margin-bottom: 1rem;
  font-weight: 600;
  justify-content: center;
}
.item3L1Up {
  padding-bottom: 0.3rem;
  padding-left: 0.2rem;
}

.item1L1Up {
  padding-bottom: 0.3rem;
}

.leanrup1 {
  margin-top: 0.5rem;
  border: 0;
  width: 95%;
  background-color: aliceblue;
  color: blue;
}

.imgup{
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: flex-start;
  width: 25%;
  
}


.imgup2{
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: flex-start;
  width: 40%;
  
}

.imgup3{
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: flex-start;
  width: 50%;
  
}

.barrinhaup {
  width: 15%;
  height: 0.15rem;
  background-color: black;
  color: whitesmoke;
}

.barrinhaup2 {
  width: 15%;
  height: 0.15rem;
  background-color: black;
  color: whitesmoke;
}

.barrinhaup3 {
  width: 15%;
  height: 0.15rem;
  background-color: black;
  color: whitesmoke;
}


@media screen and (max-width: 760px) {

  .Card1Up{
    width: 95% !important;
  }
 

  .L3 {
    font-size: 0.7rem;
  }

  .iconup2 {
    width: 20px !important;
    height: 20px !important;
  }

  .cardresponsive {
    display: flex;
    flex-direction: column !important;
  }

  .imgup{
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }

  .imgup2{
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }

  .imgup3{
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }


  .barrinhaup {
    margin-bottom: 0.4rem;
  }

  .barrinhaup2 {
    margin-bottom: 0.4rem;
  }
  .barrinhaup3 {
    margin-bottom: 0.4rem;
  }

  .title1up {
    margin-bottom: 0.2rem;
    font-size: 1.8rem;
    padding-bottom: 0.3rem;
  }

  .iconup {

    width: 8px;
    height: 8px;
  }

  .iconup2 {

    width: 8px;
    height: 8px;
  }

  .item1L1Up {
    margin-top: 0.0rem;
    font-size: 0.3rem;
    padding-bottom: 0.2rem;
  }

  .text1up {
    margin-bottom: 0rem;
    color: grey;
  }


  .titleinfo{
    display: flex;
    text-align: left;
    font-size: 1.2rem;
    margin-bottom: 0.5rem !important;
  }


  .text1Co {
    display: flex;
    text-align: center;
    font-size: 0.45rem;
    padding-right: 0.2rem;
    color: grey;
  }




}