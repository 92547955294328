.RCA {
  display: flex;
  text-align: center;
  color: rgb(51, 50, 50);
  width: 20%;
}

.offcanvas {
  width: 60%;
  justify-self: center;
}


#perfilbutton {
  width: 5rem;
  margin-left: -8px;
}


.ajustedrop {
  display: flex;
  flex-direction: row;
}

.imgPerfil{
margin-right: 0.3rem;
margin-left: -0.1rem;
}

.textdropdown {
  font-size: 1rem;
  font-weight: 500;
}

.imgSair{
  margin-right: 0.4rem;
  }

.grafico1 {
  margin-top: 20px;
  margin-bottom: -45px;
}

.droptoggle {
  background: none !important;
  border: none !important;
}

.perfildrop {
  width: 26px;
}

.dropdownmenu {
  margin-right: 3rem;
  margin-top: 3rem;
  background-color: #F3EED5;
}

.droptoggle:active {
  background: none !important;
  border: none !important;
}


.TopDash{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.disablebutton{
  margin-top: 4rem;
  height: 3rem;
  margin-left: 1rem;
  background-color: #59424B !important;
  border: none;
  font-weight: 500;
  font-size: 1.1rem;
  border-radius: 1rem;
}


.scanhistory {
margin-top: 4rem;
color: grey;
font-weight: 450;
font-size: 1.2rem;
font-family: "Public Sans", sans-serif;
}

.griddireito {
  display: flex;
  flex-direction: row;
  font-family: "Public Sans", sans-serif;
}




.SubtitleVE{
  margin-top: -1rem;
  font-size: 1.3rem;
  color: #40252C;
  margin-left: 0.5rem;
  font-weight: 500;
  font-family: "Public Sans", sans-serif;

}
.SubtitleVES{
  margin-top: 0.5rem;
  font-size: 1rem;
  color: #40252C;
  display: flex;
  flex-direction: row;
  padding-bottom: -1rem;
  padding-top: 0.3rem;
  margin-bottom: -1.2rem;
  margin-left: 0.5rem;
  background-color: #FFE4FC;
  font-weight: 500;
  padding-right: 4rem;
  border-radius: 0.5rem;
  font-family: "Public Sans", sans-serif;
}


.SubtitleVESA{
  margin-top: 0.5rem;
  font-size: 1rem;
  color: #40252C;
  display: flex;
  flex-direction: row;
  padding-bottom: -1rem;
  padding-top: 0.3rem;
  margin-bottom: -1.2rem;
  margin-left: 0.5rem;
  background-color: #FFE2E5;
  font-weight: 500;
  padding-right: 4rem;
  border-radius: 0.5rem;
  font-family: "Public Sans", sans-serif;
}

.SubtitleVESM{
  margin-top: 0.5rem;
  font-size: 1rem;
  color: #40252C;
  display: flex;
  flex-direction: row;
  padding-bottom: -1rem;
  padding-top: 0.3rem;
  margin-bottom: -1.2rem;
  margin-left: 0.5rem;
  background-color: #FFEECD;
  font-weight: 500;
  padding-right: 4rem;
  border-radius: 0.5rem;
  font-family: "Public Sans", sans-serif;
}

.SubtitleVESB{
  margin-top: 0.5rem;
  font-size: 1rem;
  color: #40252C;
  display: flex;
  flex-direction: row;
  padding-bottom: -1rem;
  padding-top: 0.3rem;
  margin-bottom: -1.2rem;
  margin-left: 0.5rem;
  background-color: #D3FFDF;
  font-weight: 500;
  padding-right: 4rem;
  border-radius: 0.5rem;
  font-family: "Public Sans", sans-serif;
}

.logocrit {
  display: flex;
  align-self: center;
  justify-self: center;
  padding-bottom: 0.4rem;
  padding-left: 0.4rem;
}

.logoalta {
  display: flex;
  align-self: center;
  justify-self: center;
  padding-bottom: 0.4rem;
  padding-left: 0.4rem;
}

.logomedia {
  display: flex;
  align-self: center;
  justify-self: center;
  padding-bottom: 0.4rem;
  padding-left: 0.4rem;
}



.SubtitleVEA {
  margin-top: -1rem;
  font-size: 1rem;
  padding-right: 0.5rem;
  margin-left: 0.5rem;
  font-family: "Public Sans", sans-serif;
}

.SubtitleVEC {
  margin-top: -1rem;
  font-size: 1rem;
  padding-right: 0.8rem;
  margin-left: 0.8rem;
  font-family: "Public Sans", sans-serif;
}


.SubtitleVER {
  margin-top: -1rem;
  font-size: 1rem;
  color: rgb(185, 0, 0);
  padding-right: 0.5rem;
  margin-left: 0.5rem;
  font-family: "Public Sans", sans-serif;
}


.titlelist {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.squaregreen {
  width: 10px;
  height: 10px;
  background-color: green;
  margin-right: 0.5rem;
}

.squareyellow {
  width: 10px;
  height: 10px;
  background-color: rgb(255, 217, 0);
  margin-right: 0.5rem;
}

.squarered {
  width: 10px;
  height: 10px;
  background-color: rgb(185, 0, 0);
  margin-right: 0.5rem;
}

.squarerow {
  display: flex;
  flex-direction: row;
  align-items: center;
  
}

.squarecolumn {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: start !important;
  
}

.grafico-texto {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.card1g {
  display: flex;
  flex-direction: column;
}

.fistpartdash{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 0.8rem ;
  font-family: "Public Sans", sans-serif;
  
}

.subtitlevulnerabilidades {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-radius: 55% !important;
  align-items: center;
  font-family: "Public Sans", sans-serif;
}

.riskscore {
  padding-bottom: 3rem;
}

.VulCard {
  font-family: "Public Sans", sans-serif;
  margin-top: 12rem;
  display: flex;
  flex-direction: column;
}




.VEGráfico {
  font-size: 1rem;
}

.Card1ssc{
  margin-top: 6rem;
  width: 35%;
  height: 340px;
  background: none;
  border: none;
  margin-right: 2rem;
  
}

.CardRisco{
  background-color: #FEFAEE;
  margin-top: 4rem;
  border-radius: 2rem;
}

.tablecard {
  background-color: #FEFAEE;
}



.textrisco {
  margin-right: 5rem;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}

.subcardrisco {
  display: flex;
  flex-direction: row;
}

.h3risco{
  font-size: 1rem;
  color: #59424B ;
}

.imgrisco{
  align-self: center;
  width: 2.3rem;
  margin-right: 1.4rem;
  margin-left: 0.3rem;
}

.prisco{
  font-size: 0.8rem;
  color: #8C7D81;
}


.download2 {
  display: flex;
  align-self: center;
  text-decoration: none;
}

.download2:hover {
  display: flex;
  align-self: center;
  text-decoration: none;
}

.buttonrisco{
  height: 2.5rem;
  align-self: center;
  width: 100%;
  border-radius: 0.7rem;
  background-color: #59424B;
  border: none;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 400;
 
}

.buttonrisco:hover {
  background-color: #40252C;
}

.topcard2ssc{
  display: flex;
  flex-direction: column;
  width: 45%;
  
}
.RCA{
  width: 100%;
  border-radius: 2rem;
}


.topcard3ssc {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.Card3ssc {
  width: 100%;
  border: none;
  margin: 0;
  border-radius: 2rem;

}


.mapcontainer {
  border-radius: 2rem;
}


@media screen and (max-width: 1850px) {

  .prisco{
    font-size: 0.7rem;
    color: #8C7D81;
  }


  .Card1ssc {
    height: 80%;
    justify-content: flex-end;
    align-self: flex-end; 
    margin-bottom: 3rem;
  }

  .VulCard {
    width: 70%;
    font-family: "Public Sans", sans-serif;
    margin-top: 12rem;
    display: flex;
    flex-direction: column;
  }

  .subtitlevulnerabilidades {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-radius: 55% !important;
    align-items: center;
    font-family: "Public Sans", sans-serif;
  }



  .SubtitleVES {
    padding-right: 2rem;
  }

  .SubtitleVESA {
    padding-right: 2rem;
  }

  .SubtitleVESM {
    padding-right: 2rem;
  }

  .SubtitleVESB {
    padding-right: 2rem;
  }

}
 

@media screen and (max-width: 760px) {

  #SSC {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Card1ssc {
    width: 80%;
  }

  .RCA {
    width: 80%;
  }

  .Card3ssc {
    width: 80%;
  }

  .VEGráfico {
    margin-top: 1.5rem;
    font-size: 1.2rem !important;
  }


}


