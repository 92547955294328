
.s {
  background-color: whitesmoke;
  padding-top: 5rem;
  padding-left: 3rem;
  margin: 0;
}

.backgroundpolitic{
  background-color: whitesmoke;
  padding-bottom: 1rem;
}

.title-p {
  margin:0;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  text-align: flex-start;
} 

.text-p {
  font-size: 1.2rem;
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 10rem;
  color: grey;
  display: flex;
  text-align: flex-start;
  margin: 0;
  flex-direction: column;
  list-style:none;
}

.li-P {
  padding-left: 0.7rem;
}

.grid-s {
  margin-bottom: 8rem;
  font-family: "Literata", serif;
}

@media screen and (max-width: 1360px) {

  .title-p {
    margin:0;
    font-size: 1.2rem;
    font-weight: bold;
    display: flex;
    text-align: flex-start;
    padding-right: 4rem;
  } 


  .text-p {
    font-size: 0.8rem;
    padding-right: 4rem;
  }
  




}

