
.metade {
  display: flex;
  flex-direction: row;
  background-color: rgb(31, 31, 31);
}



.LoginSec {
  display: flex;
  flex-direction: column;
  background-color: white;
  color: rgb(0, 0, 0);
  font-family: "Montserrat", sans-serif;
  padding-top: 6rem;
  padding-bottom: 18rem;
  text-align: left;
  margin-right: 4rem;
  padding-left: 5rem;
  padding-right: 5rem;
}

.EmailSecMain {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FEFAEE;
}

.EmailSec {
  display: flex;
  flex-direction: column;
  background-color: #FEFAEE;
  color: black;
  font-family: "Montserrat", sans-serif;
  padding-top: 6rem;
  padding-bottom: 18rem;
  text-align: left;
  margin-right: 4rem;
}

.emailtitle {
text-align: left;
font-weight: 600;
font-size: 3.6rem;
}

.emailsubtitle {
  color: rgb(71, 71, 71);
  font-weight: bold;
  font-size: 1rem;
  padding-top: 1rem;
  text-align: start;
}

.emailbutton {
  margin-top: 0.4rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 40%;
  color: black;
  font-weight: 600;
  border: 0;
  background-color: #FFBB3C;
}

.linkemail {
  margin-top: 2rem;
  font-size: 0.9rem;
}

.LoginEmailLink {
  color: black !important;
  font-weight: 600;
  text-decoration: none;
}

@media screen and (max-width: 760px) {
  .EmailSec {

    margin-right: 0rem;
    margin-left: 1rem;
  }

  .emailbutton {
    font-size: 0.8rem;
  }



}