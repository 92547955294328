
.backgroundinputoff {
  background-color: #F3EED5;
  border-radius: 2rem;
  margin-top: 1rem;
  padding: 1rem 2rem 1.5rem 1.5rem;
  font-size: 1.2rem;
}

.titledash{
  font-size: 0.9rem;
}

.InfoButton {
  margin-left: 4px;
  margin-bottom: 0;
  margin-top: -3px;
}

.offteste {
  width: 40% !important;
  background-color: #FEFAEE
  ; /* Defina a largura desejada */
}

.cabeçalho {
  color: grey;
}

.offtitle {
  margin-left: 1.4rem;
}

.bodymfa {
  margin-left: 1.5rem;
  font-family: "Public Sans", sans-serif;
  font-size: 0.8rem;
  color: #40252C;
}

.subtitulo {
  margin-top: 3rem;
  font-family: "Public Sans", sans-serif;
  color: #40252C;
  font-size: 1.3rem;
  font-weight: 600;
}

.boldlist {
  text-decoration: underline;
}

.testeinput {
  margin-top: 0.5rem;
  padding-top: 1rem !important; 
  width: 20px;
  height: 20px;
  appearance: none;
  border-color: #40252C !important;
  color: #40252C !important;
  background-color: #F3EED5 !important;
  border: 2px solid #ccc; /* Cor padrão da borda */
  border-radius: 4px; /* Bordas arredondadas */
  cursor: pointer;
  position: relative; /* Necessário para posicionar o checkmark */
}

.testeinput:checked {
  background-color: #40252C !important;
  color: white !important;
  border-color: #40252C;
}

.testeinput:checked::after {
  content: '✓'; /* Símbolo de check */
  color: white; /* Cor do símbolo de check */
  font-size: 16px; /* Tamanho do símbolo de check */
  position: absolute; /* Posicionamento absoluto dentro do checkbox */
  top: 50%; /* Centraliza verticalmente */
  left: 50%; /* Centraliza horizontalmente */
  transform: translate(-50%, -50%); /* Ajusta a posição para o centro */
}



.offcanvasdash {
  width: 40% !important; /* Defina a largura desejada */
}

.bodydash {
  margin-left: 2rem;
}

.subtitledash {
  font-size: 1.6rem;
  font-weight: 400;
}

.Blocodash {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #F3EED5;
  border-radius: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 2rem;
  padding-top: 0.7rem;
  padding-bottom: 0.5rem;
  margin-top: 2rem;
  margin-right: 1.5rem;
}

.subblocdash {
  text-align: center;
}

.textdash {
  padding-top: 0.3em;
}

.textdescricao {
  margin-top: 3rem;
  margin-right: 1.5rem;
}

.listarecomendacao {
  margin-left: 1rem;
  margin-bottom: 2rem;
}

.lista {
  
  margin-left: 1rem;
  list-style:circle;
}

.sublista {
  
  margin-left: 2.5rem;
  list-style:circle;
}

.referencia {
  margin-top: 2rem;
}
