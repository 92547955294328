.teste {
  background-color: whitesmoke;
  padding-bottom: 50px;
  padding-top: 50px;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.abutton {
  text-decoration: none;
  color: #000000;
}

.imgg {

  width: 50rem;
  height: 60%;
}

.img {
  display: flex;
  margin-top: -6rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 6rem;
}


.linkfooter {
  padding-top: 2rem;
  text-decoration: none;
  color: black;
  font-family: "Barlow", sans-serif;


}

.barrinha {
  background-color: black;
  width: 15%;
  height: 0.5%;
  padding-bottom: 1.5px;
  margin-right: 23rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.comboweb {
  margin-top: 5%;
  color: black;
}

.cardtext {
  margin-bottom: 40px;
  font-size: 0.8rem;
  font-family: "Barlow", sans-serif;
  padding-right: 1.5rem;
}



.CardMain {
  padding-bottom: 5rem;
}

.cardlink {
  padding-bottom: 40px;
  font-family: "Barlow", sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  text-decoration: none;

}


.cardtitle {
  margin-top: 0.5rem;
  font-family: "Inter", sans-serif;
  font-size: 1.3rem;
  font-weight: bolder;
  
}

.subtitle {
  margin-top: 1rem;
  font-family: "Public Sans", sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
  color:#858686;
  padding-right: 0.5rem;
  
}

.subtitlepreto {
  margin-top: 1rem;
  font-family: "Public Sans", sans-serif;
  font-size: 0.8rem;
  font-weight: 700;
  color:#000000;
  padding-right: 0.5rem;
  
}

.amarelo2{
  background-color: yellow;
  margin-right: 58%;
  padding-left: 0.2rem;
}

.cardtitleamarelo {
  
  margin-top: 0.5rem;
  font-family: "Inter", sans-serif;
  font-size: 1.3rem;
  font-weight: bolder;
  color: black;

  
}

.CardMain {
  width: 30%;
}

.cardimg {
  width: 100%;
  height: 10rem;
}

.subtext2 {
  display: flex;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding-left: 1.3rem;
  padding-right: 1.3rem;
  font-family: "Public Sans", sans-serif;
}

.subtext3 {
  display: flex;
  font-size: 1.4rem;
  font-weight: 400;
  text-align: flex-start;
  margin-left: -6rem;
  padding-right: 1.3rem;
  font-family: "Public Sans", sans-serif;

}

.subtext4 { 
  display: flex;
  font-size: 1.4rem;
  margin-top: -1rem;
  font-weight: bold;
  text-align: center;
  padding-left: 15rem;
  font-family: "Public Sans", sans-serif;
}

.text2 {
  display: flex;
  flex-direction: row;
  font-size: 45px;
  font-weight: bold;
}

.TextGroup {
  padding-top: 2rem;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Literata", serif;
  padding-left: 10rem;
  padding-right: 10rem;
}

.TextGroup2 {
  padding-top: 2rem;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: "Literata", serif;
  padding-left: 0rem;
  padding-bottom: 1rem;
  padding-right: 10rem;
}

.fundo {
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  background-color: #FEFAEE;
  justify-content: space-between;
  padding-left: 4rem;
  padding-right: 15rem;
}


.h1text {
  font-size: 3.4rem;
  font-weight: 800;
  padding-right: 6rem;
  
  justify-content: flex-start;
  margin-bottom: 1.8rem;
}

.h7 {
  font-size: 1rem;
  font-weight: 200;
  margin-top: 25px !important;
  margin-bottom: 3rem;
  font-family: "Public Sans", sans-serif;
}

.linkh6 {
  text-decoration: underline;
  color: black;
  font-weight: bold;
  margin-left: 5px;
}

.checkbox {
  margin-top: 1.3rem;
  font-size: 1rem;
  font-weight: 200;
  font-family: "Public Sans", sans-serif;

}

.imgcard2{

  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.checkboxtext {
  padding-top: 8px;
  margin-top: 8px;
  font-family: "Public Sans", sans-serif;
}

.whatsapp-icon {
  margin-bottom: -0.25rem;
  font-size: 1.5rem;
  color: black;
}


.InputHome {
  width: 400px;
  height: 50px;
  font-size: 1rem;
  padding-left: 0.9rem;
}

.ButtonInputHome {
  width: 120px;
  height: 50px;
  margin-left: 10px;
  font-size: 1rem;
  background-color: rgb(247, 201, 0);
  font-weight: bold;
  border: 0;
  font-family: "Public Sans", sans-serif;
}

.ButtonInputcard {
  width: 300px;
  height: 60px;
  border: 0;
  font-size: 1rem;
  background-color: rgb(247, 201, 0);
  font-weight: bold;
  font-family: "Public Sans", sans-serif;
}

.textbuttoncontainer {
  text-align: center;
}

.subtextbuttoncontainer {
  font-size: 0.6rem;
  color: grey;
  padding-bottom: 1.5rem;
}

.buttoncontainer {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
  padding-bottom: 4rem;
  margin-top: -3rem;
  background-color: whitesmoke;
}

.emailgroup {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.h3text {
  margin-top: 10px;
  font-size: 1.4rem;
  margin-right: 15rem;
  font-weight: bold !important;
  font-family: "Public Sans", sans-serif;
}

.h4text {
  margin-top: 1px;
  font-size: 1rem;
  margin-right: 1rem;
  font-weight: 100 !important;
  text-align: center;
  color: black;
  font-family: "Public Sans", sans-serif;
}

.text {
  padding-top: 30px;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;

  font-size: 2rem;
  color: black;
  font-family: "Literata", sans-serif;
  font-weight: 800;

}



.cardtext {
  font-size: 0.9rem;
  margin-bottom: 1rem;
  font-weight: 500;
  font-family: "Public Sans", sans-serif;
}

.GTC {
  padding-bottom: 3rem;
  padding-right: 1rem;
  padding-left: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.footer {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  padding-top: 30px;
  padding-right: 7rem;
  padding-left: 7rem;
  padding-bottom: 2rem;
  background-color: #FEFAEE;
  color: antiquewhite;
  font-family: "Barlow", sans-serif;
}

.column {
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 7rem;
}

h6 {
  font-family: 'Courier New', Courier, ;
  padding-top: 1rem;
  font-size: large;
  font-weight: bold;
  justify-content: center;
  color: black;
}

.cat {
  display: flex;
  align-content: center;
  flex-direction: column;

}

.caption {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  background-color: #FEFAEE;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: grey;
}

.disclaimer {

  font-style: italic;
  font-size: 0.8rem;
}

.TCard2 {
  width: 40rem;
}

.TCard {
  width: 18rem;
}


@media screen and (max-width: 1200px) {

  .img {
    display: flex;
    margin-top: 0;
    align-items: center;
    justify-content: center;
    margin-right: -15rem;
    
  }

  .h1text {
    font-size: 2.2rem !important;
    padding-right: 0.5rem !important;
    justify-content: flex-start;
  }

  .h3text {
    margin-top: 10px;
    font-size: 1rem;
    margin-right: 2rem !important;
    font-weight: bold !important;
    font-family: "Public Sans", sans-serif;
  }

  .InputHome {
    width: 250px;
    height: 50px;
    font-size: 1rem;
    padding-left: 0.9rem;
  }

  .ButtonInputHome {
    width: 80px;
    height: 50px;
    margin-left: 10px;
    font-size: 0.7rem;
    background-color: rgb(247, 201, 0);
    font-weight: bold;
    border: none;
    border-style: hidden;
    font-family: "Public Sans", sans-serif;
  }

  .amarelo2{
    background-color: yellow;
    margin-right: 31%;
    padding-left: 0.2rem;
  }
  

  
}



@media screen and (max-width: 1360px) {

  .amarelo2{
    background-color: yellow;
    margin-right: 30%;
    padding-left: 0.2rem;
  }

  
  

  .img {
    display: flex;
    margin-top: 0;
    align-items: center;
    justify-content: center;
    margin-right: -10rem;
    
  }

  .text2 {
    font-size: 1.6rem;
  }

  .h1text {
    font-size: 2.2rem;
    padding-right: 12rem;
    justify-content: flex-start;
  
  }
}


@media screen and (max-width: 1560px) {

  .imgg {
    height: 40% !important;
    width: 40rem;
   }

}

@media screen and (max-width: 1300px) {
  .imgg {
   height: 40% !important;
   width: 30rem;
  }
 }

@media screen and (max-width: 1100px) {
  .imgg {
   height: 40% !important;
   width: 28rem;
  }
 }


@media screen and (max-width: 900px) {

  .teste {
    flex-direction: column;
    align-items: center;
  }
  .cardimg {
    width: 100%;
    height: 10rem;
  }

  
  .imgg {
    width: 15rem;
    height: 60%;
    margin-top: 2rem;
    margin-right: 2rem;
  }
  .img {

    padding: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-left: 3rem;  
  }
}



@media screen and (max-width: 900px) {

  .TextGroup2 {
    padding-top: 2rem;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Literata", serif;
    padding-left: 0rem;
    padding-bottom: 1rem;
    align-items: center;
    padding-right: 0rem;
  }

  .subtext3 {
    display: flex;
    font-size: 0.7rem;
    font-weight: 400;
    justify-content: center;
    text-align: center;
    margin-left: 0rem;
    padding-right: 3.5rem;
    font-family: "Public Sans", sans-serif;
  
  }
  
  .subtext4 { 
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 0.7rem;
    margin-top: -1rem;
    font-weight: bolder;
    text-align: center;
    padding-left: 2.7rem;
    font-family: "Public Sans", sans-serif;
  }




  .cardlink {
    font-size: 1rem;
    padding-bottom: 0.3rem;
  }

  .TextGroup {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .fundo {
    padding-left: 1rem;
  }

  .CardMain {
    width: 60%;
    padding-bottom: 1rem;
    margin-bottom: 3rem;
  }

  .cardimg {
    width: 100%;
    height: 10rem;
  }

  .cardtitle {
    font-size: 1rem !important;

  }
  .amarelo2{
    background-color: yellow;
    margin-right: 20%;
    padding-left: 0.2rem;
  }

  .cardtitleamarelo {
    font-size: 1rem !important;

  }


  .caption {
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background-color: #FEFAEE;
    display: flex;
    flex-direction: column;
    
    justify-content: center;
    color: grey;
  }

  .webicon {
    width: 15px;
    height: 15px;
    color: black;
  }


  .text2 {
    font-size: 1.1rem;
  }


  .checkboxtext {
    margin: 0;
    padding-top: 1.25rem;
    font-size: 0.7rem;
    font-family: "Public Sans", sans-serif;
  }



  .img {
    width: 200px;
    height: 400px;
    padding: 0;
    margin-right: 2rem;
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
  
  }

  .vcx {
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
  }

  .InputHome {
    width: 40%;
    height: 30px;
    font-size: 0.4rem;
    padding-left: 15px;
  }

  .ButtonInputHome {
    width: 10%;
    height: 30px;
    margin-left: 5px;
    font-size: 0.6rem;
    background-color: rgb(247, 201, 0);
    font-weight: bold;
  }

  .emailgroup {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
  }

  .h1text {
    font-size: 2.4rem !important;
  }

  .h7 {
    font-size: 0.7rem;
    font-weight: 200;
    margin-top: 25px !important;
    margin-bottom: 3rem;
    font-family: "Public Sans", sans-serif;
  }

  .cardbody {
    margin-left: 0.5rem;
  }


  .h3text {
    margin-right: 4rem !important;
    margin-top: 10px;
    font-size: 0.8rem;
    margin-right: 0;
    font-weight: bold !important;
    font-family: "Public Sans", sans-serif;
  }

  .h4text {
    margin-top: 10px;
    font-size: 0.8rem;
    padding-right: 0;
    margin-right: 2rem;
    font-weight: 400 !important;
  }

  .text {
    padding-top: 30px;
    padding-bottom: 3rem;
    display: flex;
    font-size: 1rem;
    color: black;
    font-weight: 800;
  }



  .fundo {
    padding-top: 1rem;
    display: flex;
    flex-direction: column-reverse;
    padding-right: 0 !important;

  }


  .text {
    padding-top: 30px;
    padding-bottom: 0.5rem;
    display: flex;
    justify-content: flex-start;
    font-size: 27px;
  }

  .footer {
    flex-direction: row;
    align-items: flex-start;
    padding: 30px;
    padding-right: 20px;
    font-size: 8px;
    justify-content: space-around;
    font-family: "Barlow", sans-serif;
  }

  h6 {
    font-size: 0.7rem;
  }

  h2 {
    font-size: 18px;
  }

  .column {
    padding: 0;
    margin-bottom: 5rem;
    display: flex;
    justify-content: flex-start;
    font-size: 1rem;
    font-family: "Barlow", sans-serif;
  }

  .cat {
    padding-right: 10px;
    margin-bottom: 2rem;
  }

  .mainfooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  .GTC {
    padding-bottom: 0.5rem;
    padding-right: 0.3rem;
    padding-left: 0.3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: 5px;
  }

  .TCard2 {
    width: 30rem;
    margin-left: 0.8rem;
    font-size: 6px;
  }

  .TitleCard2 {
    font-size: 18px;
  }

  #SubCard2 {
    font-size: 12px !important;
  }

  .TCard {
    width: 20rem;

    margin-left: 0.8rem;
    font-size: 7px;
  }

  .TitleCard {
    font-size: 15px;
  }

  #card3 {
    font-size: 12px !important;

  }
}


@media screen and (max-width: 767px) {


  .InputHome {
    width: 7rem;
    height: 30px;
    font-size: 0.6rem;
    padding-left: 5px;
  }

  .ButtonInputHome {
    width: 20%;
    height: 30px;
    margin-left: 5px;
    font-size: 0.6rem;
    background-color: rgb(247, 201, 0);
    font-weight: bolder;
    border: 0;
  }




}

@media screen and (max-width: 650px) {


  .h1text {
    font-size: 1.4rem !important;
  }

  
  .img {
    width: 300px;
    height: 400px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-left: 3rem;
  }


  .imgg {
    width: 15rem;
    height: 60%;

    margin-right: 2rem;
  }

}



@media screen and (max-width: 450px) {

  .subtext3 {
    font-size: 0.6rem;
  }

  .subtext4 {
    font-size: 0.6rem
  }

  .cat {
    display: none;
  }

  .cardtitle {
    text-align: center;
  }

  .cardtext {
    text-align: center !important; 
  }

  .text2 {
    font-size: 0.8rem;
  }

  .subtextbuttoncontainer {
    font-size: 0.45rem;
  }

  .InputHome {
    width: 60%;
    height: 40px;
    font-size: 0.6rem;
    padding-left: 5px;
}


.ButtonInputHome {
  width: 30%;
  height: 40px;
  margin-left: 5px;
  font-size: 0.75rem;
  background-color: rgb(247, 201, 0);
  font-weight: bolder;
  border: 0;
}

.CardMain {
  width: 95%;
  padding-bottom: 1rem;
  margin-bottom: 3rem;
}

.contratbutton {
  width: 35%;
  height: 2rem;
  font-size: 0.75rem !important;
  font-weight: bold;
}


}     

@media screen and (max-width: 375px) {

  .subtext3 {
    font-size: 0.55rem;
  }

  .subtext4 {
    font-size: 0.55rem;
    padding-left: 2rem;
  }

}


@media screen and (max-width: 320px) {

  .subtext3 {
    font-size: 0.5rem;
  }

  .subtext4 {
    font-size: 0.5rem;
    padding-left: 1rem;
  }

}